@tailwind base;
@tailwind components;
@tailwind utilities;
html,
body,
#root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Hide scrollbar while still allowing content to scroll */
::-webkit-scrollbar {
  width: 0; /* For WebKit browsers */
  background: transparent; /* Make scrollbar transparent */
}

body,
html {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}

body {
  margin: 0;
  font-family: cursive, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #6100c2;
}

h1 {
  background: -webkit-linear-gradient(
    45deg,
    #6100c2,
    rgb(103, 55, 224),
    #2b13c8,
    #07aee1
  );
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: 100% 100% !important;
  font-weight: 800; /* Use font-weight for setting the weight */
  font-size: 2em; /* Add a font-size */
  font-family: cursive, sans-serif; /* Add a font-family if desired */
}

.noPointerEvents {
  pointer-events: none;
}
